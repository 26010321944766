
@import "metronic/bootstrap";
@import "metronic/global/_plugins";
@import "metronic/global/_components";
@import "metronic/layouts/layout4/layout";
@import "metronic/layouts/layout4/themes/light";
@import "metronic/pages/login-4";
@import "slingshot/common";


.page-header.navbar {
	.page-logo {
		img.logo-default {
			margin-top: 20px;
			max-height: 55px;
			max-width: 215px;
		}
	}
}

.login {
	background-color: #000 !important;
}

.login-form .disclosure-agreement p {
  color: #afafaf;
}

.edit-portfolio .nav-tabs {
	margin-bottom: 0;
	border-bottom: 0;
}

.pagination > .disabled > a {
	cursor: default !important;
}